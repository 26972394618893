/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { QuestionChoiceUpdateRequestTranslationsInner } from './QuestionChoiceUpdateRequestTranslationsInner';
import {
  QuestionChoiceUpdateRequestTranslationsInnerFromJSON,
  QuestionChoiceUpdateRequestTranslationsInnerFromJSONTyped,
  QuestionChoiceUpdateRequestTranslationsInnerToJSON
} from './QuestionChoiceUpdateRequestTranslationsInner';

/**
 *
 * @export
 * @interface QuestionChoiceUpdateRequest
 */
export interface QuestionChoiceUpdateRequest {
  /**
   *
   * @type {string}
   * @memberof QuestionChoiceUpdateRequest
   */
  locale_code: string;
  /**
   *
   * @type {string}
   * @memberof QuestionChoiceUpdateRequest
   */
  name: string;
  /**
   *
   * @type {Array<QuestionChoiceUpdateRequestTranslationsInner>}
   * @memberof QuestionChoiceUpdateRequest
   */
  translations?: Array<QuestionChoiceUpdateRequestTranslationsInner>;
  /**
   *
   * @type {string}
   * @memberof QuestionChoiceUpdateRequest
   */
  status: QuestionChoiceUpdateRequestStatusEnum;
}

/**
 * @export
 */
export const QuestionChoiceUpdateRequestStatusEnum = {
  Active: 'active',
  Deleted: 'deleted'
} as const;
export type QuestionChoiceUpdateRequestStatusEnum =
  (typeof QuestionChoiceUpdateRequestStatusEnum)[keyof typeof QuestionChoiceUpdateRequestStatusEnum];

/**
 * Check if a given object implements the QuestionChoiceUpdateRequest interface.
 */
export function instanceOfQuestionChoiceUpdateRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'locale_code' in value;
  isInstance = isInstance && 'name' in value;
  isInstance = isInstance && 'status' in value;

  return isInstance;
}

export function QuestionChoiceUpdateRequestFromJSON(json: any): QuestionChoiceUpdateRequest {
  return QuestionChoiceUpdateRequestFromJSONTyped(json, false);
}

export function QuestionChoiceUpdateRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): QuestionChoiceUpdateRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    locale_code: json['locale_code'],
    name: json['name'],
    translations: !exists(json, 'translations')
      ? undefined
      : (json['translations'] as Array<any>).map(
          QuestionChoiceUpdateRequestTranslationsInnerFromJSON
        ),
    status: json['status']
  };
}

export function QuestionChoiceUpdateRequestToJSON(value?: QuestionChoiceUpdateRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    locale_code: value.locale_code,
    name: value.name,
    translations:
      value.translations === undefined
        ? undefined
        : (value.translations as Array<any>).map(
            QuestionChoiceUpdateRequestTranslationsInnerToJSON
          ),
    status: value.status
  };
}
