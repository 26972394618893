/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { QuestionUpdateRequestChoicesInner } from './QuestionUpdateRequestChoicesInner';
import {
  QuestionUpdateRequestChoicesInnerFromJSON,
  QuestionUpdateRequestChoicesInnerFromJSONTyped,
  QuestionUpdateRequestChoicesInnerToJSON
} from './QuestionUpdateRequestChoicesInner';
import type { QuestionUpdateRequestQuestionTranslationsValue } from './QuestionUpdateRequestQuestionTranslationsValue';
import {
  QuestionUpdateRequestQuestionTranslationsValueFromJSON,
  QuestionUpdateRequestQuestionTranslationsValueFromJSONTyped,
  QuestionUpdateRequestQuestionTranslationsValueToJSON
} from './QuestionUpdateRequestQuestionTranslationsValue';

/**
 *
 * @export
 * @interface QuestionCreateRequest
 */
export interface QuestionCreateRequest {
  /**
   *
   * @type {string}
   * @memberof QuestionCreateRequest
   */
  locale_code: string;
  /**
   *
   * @type {string}
   * @memberof QuestionCreateRequest
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof QuestionCreateRequest
   */
  level: QuestionCreateRequestLevelEnum;
  /**
   *
   * @type {string}
   * @memberof QuestionCreateRequest
   */
  answer_type: QuestionCreateRequestAnswerTypeEnum;
  /**
   * Question Translations
   * @type {{ [key: string]: QuestionUpdateRequestQuestionTranslationsValue; }}
   * @memberof QuestionCreateRequest
   */
  question_translations?: { [key: string]: QuestionUpdateRequestQuestionTranslationsValue };
  /**
   *
   * @type {Array<QuestionUpdateRequestChoicesInner>}
   * @memberof QuestionCreateRequest
   */
  choices?: Array<QuestionUpdateRequestChoicesInner>;
}

/**
 * @export
 */
export const QuestionCreateRequestLevelEnum = {
  Booking: 'booking',
  Participant: 'participant'
} as const;
export type QuestionCreateRequestLevelEnum =
  (typeof QuestionCreateRequestLevelEnum)[keyof typeof QuestionCreateRequestLevelEnum];

/**
 * @export
 */
export const QuestionCreateRequestAnswerTypeEnum = {
  Text: 'text',
  Select: 'select'
} as const;
export type QuestionCreateRequestAnswerTypeEnum =
  (typeof QuestionCreateRequestAnswerTypeEnum)[keyof typeof QuestionCreateRequestAnswerTypeEnum];

/**
 * Check if a given object implements the QuestionCreateRequest interface.
 */
export function instanceOfQuestionCreateRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'locale_code' in value;
  isInstance = isInstance && 'name' in value;
  isInstance = isInstance && 'level' in value;
  isInstance = isInstance && 'answer_type' in value;

  return isInstance;
}

export function QuestionCreateRequestFromJSON(json: any): QuestionCreateRequest {
  return QuestionCreateRequestFromJSONTyped(json, false);
}

export function QuestionCreateRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): QuestionCreateRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    locale_code: json['locale_code'],
    name: json['name'],
    level: json['level'],
    answer_type: json['answer_type'],
    question_translations: !exists(json, 'question_translations')
      ? undefined
      : mapValues(
          json['question_translations'],
          QuestionUpdateRequestQuestionTranslationsValueFromJSON
        ),
    choices: !exists(json, 'choices')
      ? undefined
      : (json['choices'] as Array<any>).map(QuestionUpdateRequestChoicesInnerFromJSON)
  };
}

export function QuestionCreateRequestToJSON(value?: QuestionCreateRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    locale_code: value.locale_code,
    name: value.name,
    level: value.level,
    answer_type: value.answer_type,
    question_translations:
      value.question_translations === undefined
        ? undefined
        : mapValues(
            value.question_translations,
            QuestionUpdateRequestQuestionTranslationsValueToJSON
          ),
    choices:
      value.choices === undefined
        ? undefined
        : (value.choices as Array<any>).map(QuestionUpdateRequestChoicesInnerToJSON)
  };
}
