/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { QuestionChoiceTranslationSchema } from './QuestionChoiceTranslationSchema';
import {
  QuestionChoiceTranslationSchemaFromJSON,
  QuestionChoiceTranslationSchemaFromJSONTyped,
  QuestionChoiceTranslationSchemaToJSON
} from './QuestionChoiceTranslationSchema';

/**
 *
 * @export
 * @interface QuestionChoiceCreateRequest
 */
export interface QuestionChoiceCreateRequest {
  /**
   *
   * @type {string}
   * @memberof QuestionChoiceCreateRequest
   */
  locale_code: string;
  /**
   *
   * @type {string}
   * @memberof QuestionChoiceCreateRequest
   */
  name: string;
  /**
   *
   * @type {Array<QuestionChoiceTranslationSchema>}
   * @memberof QuestionChoiceCreateRequest
   */
  translations?: Array<QuestionChoiceTranslationSchema>;
}

/**
 * Check if a given object implements the QuestionChoiceCreateRequest interface.
 */
export function instanceOfQuestionChoiceCreateRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'locale_code' in value;
  isInstance = isInstance && 'name' in value;

  return isInstance;
}

export function QuestionChoiceCreateRequestFromJSON(json: any): QuestionChoiceCreateRequest {
  return QuestionChoiceCreateRequestFromJSONTyped(json, false);
}

export function QuestionChoiceCreateRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): QuestionChoiceCreateRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    locale_code: json['locale_code'],
    name: json['name'],
    translations: !exists(json, 'translations')
      ? undefined
      : (json['translations'] as Array<any>).map(QuestionChoiceTranslationSchemaFromJSON)
  };
}

export function QuestionChoiceCreateRequestToJSON(value?: QuestionChoiceCreateRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    locale_code: value.locale_code,
    name: value.name,
    translations:
      value.translations === undefined
        ? undefined
        : (value.translations as Array<any>).map(QuestionChoiceTranslationSchemaToJSON)
  };
}
