/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DataTableFiltersCreateRequestFilterCriteriaFiltersValue } from './DataTableFiltersCreateRequestFilterCriteriaFiltersValue';
import {
  DataTableFiltersCreateRequestFilterCriteriaFiltersValueFromJSON,
  DataTableFiltersCreateRequestFilterCriteriaFiltersValueFromJSONTyped,
  DataTableFiltersCreateRequestFilterCriteriaFiltersValueToJSON
} from './DataTableFiltersCreateRequestFilterCriteriaFiltersValue';

/**
 *
 * @export
 * @interface DataTableFiltersCreateRequestFilterCriteria
 */
export interface DataTableFiltersCreateRequestFilterCriteria {
  /**
   *
   * @type {number}
   * @memberof DataTableFiltersCreateRequestFilterCriteria
   */
  rows: number;
  /**
   *
   * @type {number}
   * @memberof DataTableFiltersCreateRequestFilterCriteria
   */
  first: number;
  /**
   *
   * @type {Array<string>}
   * @memberof DataTableFiltersCreateRequestFilterCriteria
   */
  columns?: Array<string>;
  /**
   *
   * @type {{ [key: string]: DataTableFiltersCreateRequestFilterCriteriaFiltersValue; }}
   * @memberof DataTableFiltersCreateRequestFilterCriteria
   */
  filters: { [key: string]: DataTableFiltersCreateRequestFilterCriteriaFiltersValue };
}

/**
 * Check if a given object implements the DataTableFiltersCreateRequestFilterCriteria interface.
 */
export function instanceOfDataTableFiltersCreateRequestFilterCriteria(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'rows' in value;
  isInstance = isInstance && 'first' in value;
  isInstance = isInstance && 'filters' in value;

  return isInstance;
}

export function DataTableFiltersCreateRequestFilterCriteriaFromJSON(
  json: any
): DataTableFiltersCreateRequestFilterCriteria {
  return DataTableFiltersCreateRequestFilterCriteriaFromJSONTyped(json, false);
}

export function DataTableFiltersCreateRequestFilterCriteriaFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): DataTableFiltersCreateRequestFilterCriteria {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    rows: json['rows'],
    first: json['first'],
    columns: !exists(json, 'columns') ? undefined : json['columns'],
    filters: mapValues(
      json['filters'],
      DataTableFiltersCreateRequestFilterCriteriaFiltersValueFromJSON
    )
  };
}

export function DataTableFiltersCreateRequestFilterCriteriaToJSON(
  value?: DataTableFiltersCreateRequestFilterCriteria | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    rows: value.rows,
    first: value.first,
    columns: value.columns,
    filters: mapValues(value.filters, DataTableFiltersCreateRequestFilterCriteriaFiltersValueToJSON)
  };
}
