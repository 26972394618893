/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { QuestionChoiceByIdReadResponseQuestionChoice } from './QuestionChoiceByIdReadResponseQuestionChoice';
import {
  QuestionChoiceByIdReadResponseQuestionChoiceFromJSON,
  QuestionChoiceByIdReadResponseQuestionChoiceFromJSONTyped,
  QuestionChoiceByIdReadResponseQuestionChoiceToJSON
} from './QuestionChoiceByIdReadResponseQuestionChoice';

/**
 *
 * @export
 * @interface QuestionChoiceByIdReadResponse
 */
export interface QuestionChoiceByIdReadResponse {
  /**
   *
   * @type {boolean}
   * @memberof QuestionChoiceByIdReadResponse
   */
  success: boolean;
  /**
   *
   * @type {string}
   * @memberof QuestionChoiceByIdReadResponse
   */
  exception_code?: string;
  /**
   *
   * @type {string}
   * @memberof QuestionChoiceByIdReadResponse
   */
  exception_message?: string;
  /**
   *
   * @type {QuestionChoiceByIdReadResponseQuestionChoice}
   * @memberof QuestionChoiceByIdReadResponse
   */
  question_choice: QuestionChoiceByIdReadResponseQuestionChoice;
}

/**
 * Check if a given object implements the QuestionChoiceByIdReadResponse interface.
 */
export function instanceOfQuestionChoiceByIdReadResponse(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'success' in value;
  isInstance = isInstance && 'question_choice' in value;

  return isInstance;
}

export function QuestionChoiceByIdReadResponseFromJSON(json: any): QuestionChoiceByIdReadResponse {
  return QuestionChoiceByIdReadResponseFromJSONTyped(json, false);
}

export function QuestionChoiceByIdReadResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): QuestionChoiceByIdReadResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    success: json['success'],
    exception_code: !exists(json, 'exception_code') ? undefined : json['exception_code'],
    exception_message: !exists(json, 'exception_message') ? undefined : json['exception_message'],
    question_choice: QuestionChoiceByIdReadResponseQuestionChoiceFromJSON(json['question_choice'])
  };
}

export function QuestionChoiceByIdReadResponseToJSON(
  value?: QuestionChoiceByIdReadResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    success: value.success,
    exception_code: value.exception_code,
    exception_message: value.exception_message,
    question_choice: QuestionChoiceByIdReadResponseQuestionChoiceToJSON(value.question_choice)
  };
}
