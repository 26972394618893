/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ChannelProductOptionChannelConfigurationReadResponse
 */
export interface ChannelProductOptionChannelConfigurationReadResponse {
  /**
   *
   * @type {boolean}
   * @memberof ChannelProductOptionChannelConfigurationReadResponse
   */
  success: boolean;
  /**
   *
   * @type {string}
   * @memberof ChannelProductOptionChannelConfigurationReadResponse
   */
  exception_code?: string;
  /**
   *
   * @type {string}
   * @memberof ChannelProductOptionChannelConfigurationReadResponse
   */
  exception_message?: string;
  /**
   * product option channel configuration id
   * @type {string}
   * @memberof ChannelProductOptionChannelConfigurationReadResponse
   */
  product_option_channel_configuration_id: string;
  /**
   * product option id
   * @type {string}
   * @memberof ChannelProductOptionChannelConfigurationReadResponse
   */
  product_option_id: string;
  /**
   * channel id
   * @type {string}
   * @memberof ChannelProductOptionChannelConfigurationReadResponse
   */
  channel_id: string;
  /**
   * channel name
   * @type {string}
   * @memberof ChannelProductOptionChannelConfigurationReadResponse
   */
  channel_name: string;
  /**
   * external product option id for the given channel. Some internal channels do not have an external id.
   * @type {string}
   * @memberof ChannelProductOptionChannelConfigurationReadResponse
   */
  channel_external_product_option_id?: string;
  /**
   *
   * @type {string}
   * @memberof ChannelProductOptionChannelConfigurationReadResponse
   */
  channel_connection_status: ChannelProductOptionChannelConfigurationReadResponseChannelConnectionStatusEnum;
  /**
   *
   * @type {number}
   * @memberof ChannelProductOptionChannelConfigurationReadResponse
   */
  channel_commission_rate?: number;
  /**
   *
   * @type {string}
   * @memberof ChannelProductOptionChannelConfigurationReadResponse
   */
  reservable_before: string | null;
  /**
   *
   * @type {string}
   * @memberof ChannelProductOptionChannelConfigurationReadResponse
   */
  reservable_after: string | null;
}

/**
 * @export
 */
export const ChannelProductOptionChannelConfigurationReadResponseChannelConnectionStatusEnum = {
  Active: 'active',
  Inactive: 'inactive',
  Deleted: 'deleted'
} as const;
export type ChannelProductOptionChannelConfigurationReadResponseChannelConnectionStatusEnum =
  (typeof ChannelProductOptionChannelConfigurationReadResponseChannelConnectionStatusEnum)[keyof typeof ChannelProductOptionChannelConfigurationReadResponseChannelConnectionStatusEnum];

/**
 * Check if a given object implements the ChannelProductOptionChannelConfigurationReadResponse interface.
 */
export function instanceOfChannelProductOptionChannelConfigurationReadResponse(
  value: object
): boolean {
  let isInstance = true;
  isInstance = isInstance && 'success' in value;
  isInstance = isInstance && 'product_option_channel_configuration_id' in value;
  isInstance = isInstance && 'product_option_id' in value;
  isInstance = isInstance && 'channel_id' in value;
  isInstance = isInstance && 'channel_name' in value;
  isInstance = isInstance && 'channel_connection_status' in value;
  isInstance = isInstance && 'reservable_before' in value;
  isInstance = isInstance && 'reservable_after' in value;

  return isInstance;
}

export function ChannelProductOptionChannelConfigurationReadResponseFromJSON(
  json: any
): ChannelProductOptionChannelConfigurationReadResponse {
  return ChannelProductOptionChannelConfigurationReadResponseFromJSONTyped(json, false);
}

export function ChannelProductOptionChannelConfigurationReadResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ChannelProductOptionChannelConfigurationReadResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    success: json['success'],
    exception_code: !exists(json, 'exception_code') ? undefined : json['exception_code'],
    exception_message: !exists(json, 'exception_message') ? undefined : json['exception_message'],
    product_option_channel_configuration_id: json['product_option_channel_configuration_id'],
    product_option_id: json['product_option_id'],
    channel_id: json['channel_id'],
    channel_name: json['channel_name'],
    channel_external_product_option_id: !exists(json, 'channel_external_product_option_id')
      ? undefined
      : json['channel_external_product_option_id'],
    channel_connection_status: json['channel_connection_status'],
    channel_commission_rate: !exists(json, 'channel_commission_rate')
      ? undefined
      : json['channel_commission_rate'],
    reservable_before: json['reservable_before'],
    reservable_after: json['reservable_after']
  };
}

export function ChannelProductOptionChannelConfigurationReadResponseToJSON(
  value?: ChannelProductOptionChannelConfigurationReadResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    success: value.success,
    exception_code: value.exception_code,
    exception_message: value.exception_message,
    product_option_channel_configuration_id: value.product_option_channel_configuration_id,
    product_option_id: value.product_option_id,
    channel_id: value.channel_id,
    channel_name: value.channel_name,
    channel_external_product_option_id: value.channel_external_product_option_id,
    channel_connection_status: value.channel_connection_status,
    channel_commission_rate: value.channel_commission_rate,
    reservable_before: value.reservable_before,
    reservable_after: value.reservable_after
  };
}
