<template>
  <div class="grid grid-cols-12 gap-1 @container">
    <div v-if="canBeNegative" class="col-span-12 mb-2 @xs:col-span-12">
      <label v-show="showLabels" for="sign" class="mb-1 block text-sm font-medium text-gray-700">
        {{ t('portal_reservation_cutoff_sign_label') }}
      </label>
      <Select
        id="sign"
        class="w-full"
        :options="dropdownSignOptions.length !== 0 ? dropdownSignOptions : defaultSignOptions"
        :model-value="signValue"
        option-label="name"
        option-value="id"
        :placeholder="t('portal_reservation_cutoff_sign_label')"
        :disabled="disabled"
        @update:model-value="onChangeSign"
      />
    </div>

    <div class="col-span-12 @xs:col-span-4">
      <label v-show="showLabels" for="days" class="mb-1 block text-sm font-medium text-gray-700">
        {{ t('portal_reservation_cutoff_days_label') }}
      </label>
      <Select
        id="days"
        class="w-full"
        :options="daysOptions"
        option-label="label"
        option-value="value"
        :model-value="localCutoffTimeNumericFormat.days"
        :placeholder="t('portal_reservation_cutoff_days_label')"
        :disabled="disabled"
        @update:model-value="onChangeDays"
      />
    </div>
    <div class="col-span-12 @xs:col-span-4">
      <label v-show="showLabels" for="hours" class="mb-1 block text-sm font-medium text-gray-700">
        {{ t('portal_reservation_cutoff_hours_label') }}
      </label>
      <Select
        id="hours"
        class="w-full"
        :options="hoursOptions"
        option-label="label"
        option-value="value"
        :model-value="localCutoffTimeNumericFormat.hours"
        :placeholder="t('portal_reservation_cutoff_hours_label')"
        :disabled="disabled"
        @update:model-value="onChangeHours"
      />
    </div>
    <div class="col-span-12 @xs:col-span-4">
      <label v-show="showLabels" for="minutes" class="mb-1 block text-sm font-medium text-gray-700">
        {{ t('portal_reservation_cutoff_minutes_label') }}
      </label>
      <Select
        id="minutes"
        class="w-full"
        :options="minutesOptions"
        option-label="label"
        option-value="value"
        :model-value="localCutoffTimeNumericFormat.minutes"
        :placeholder="t('portal_reservation_cutoff_minutes_label')"
        :disabled="disabled"
        @update:model-value="onChangeMinutes"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import Select from 'primevue/select';
import { useField } from 'vee-validate';
import { computed } from 'vue';
import { Temporal } from '@js-temporal/polyfill';
import { useI18n } from 'vue-i18n';
import { optionAfter, optionBefore } from '@/utils/duration';

const { t } = useI18n();
const props = withDefaults(
  defineProps<{
    name: string;
    rules?: string;
    dropdownSignOptions?: Array<any>;
    canBeNegative?: boolean;
    modelValue?: string | null;
    showLabels?: boolean;
    disabled?: boolean;
  }>(),
  {
    rules: '',
    dropdownSignOptions: () => [],
    canBeNegative: false,
    modelValue: undefined,
    showLabels: true
  }
);
const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void;
}>();

const { value: cutoffTimeInTemporalFormat, handleChange } = useField<string | null>(
  () => props.name,
  props.rules,
  { initialValue: props.modelValue }
);

const signValue = computed(() => {
  const temporalString = cutoffTimeInTemporalFormat.value;
  if (!temporalString) {
    return 1;
  }
  return temporalString.startsWith('-') ? -1 : 1;
});

const localCutoffTimeNumericFormat = computed(() => {
  const temporalString = cutoffTimeInTemporalFormat.value;
  if (!temporalString) {
    return { days: 0, hours: 0, minutes: 0 };
  }
  const temporalStringWithoutSign = temporalString.replace('-', '');
  const temporal = Temporal.Duration.from(temporalStringWithoutSign);
  const rounded = temporal.round({ largestUnit: 'day', smallestUnit: 'minute' });
  return { days: rounded.days, hours: rounded.hours, minutes: rounded.minutes };
});

const daysOptions = Array.from({ length: 31 }, (_, i) => ({
  label: `${i} ${t('portal_reservation_cutoff_days_label')}`,
  value: i
}));
const hoursOptions = Array.from({ length: 24 }, (_, i) => ({
  label: `${i} ${t('portal_reservation_cutoff_hours_label')}`,
  value: i
}));
const minutesOptions = Array.from({ length: 60 }, (_, i) => ({
  label: `${i} ${t('portal_reservation_cutoff_minutes_label')}`,
  value: i
}));
const onChangeSign = (signValue: -1 | 1) => {
  const { days, hours, minutes } = localCutoffTimeNumericFormat.value;
  createTemporalStringAndEmitToParent(days, hours, minutes, signValue);
};

const onChangeDays = (days: number) => {
  const { hours, minutes } = localCutoffTimeNumericFormat.value;
  const sign = signValue.value;
  createTemporalStringAndEmitToParent(days, hours, minutes, sign);
};

const onChangeHours = (hours: number) => {
  const { days, minutes } = localCutoffTimeNumericFormat.value;
  const sign = signValue.value;
  createTemporalStringAndEmitToParent(days, hours, minutes, sign);
};

const onChangeMinutes = (minutes: number) => {
  const { days, hours } = localCutoffTimeNumericFormat.value;
  const sign = signValue.value;
  createTemporalStringAndEmitToParent(days, hours, minutes, sign);
};

const createTemporalStringAndEmitToParent = (
  days: number,
  hours: number,
  minutes: number,
  sign: -1 | 1
) => {
  const daysWithSign = sign * days;
  const hoursWithSign = sign * hours;
  const minutesWithSign = sign * minutes;
  const duration = Temporal.Duration.from({
    days: daysWithSign,
    hours: hoursWithSign,
    minutes: minutesWithSign
  })
    .round({ smallestUnit: 'minute', largestUnit: 'hour' })
    .toString();
  handleChange(duration);
  emit('update:modelValue', duration);
};

const defaultSignOptions = computed(() => [optionAfter(), optionBefore()]);
</script>
