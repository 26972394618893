/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CustomersReadResponseMeta } from './CustomersReadResponseMeta';
import {
  CustomersReadResponseMetaFromJSON,
  CustomersReadResponseMetaFromJSONTyped,
  CustomersReadResponseMetaToJSON
} from './CustomersReadResponseMeta';
import type { DataTableFiltersCreateResponseFilter } from './DataTableFiltersCreateResponseFilter';
import {
  DataTableFiltersCreateResponseFilterFromJSON,
  DataTableFiltersCreateResponseFilterFromJSONTyped,
  DataTableFiltersCreateResponseFilterToJSON
} from './DataTableFiltersCreateResponseFilter';
import type { OrdersReadResponseOrdersInner } from './OrdersReadResponseOrdersInner';
import {
  OrdersReadResponseOrdersInnerFromJSON,
  OrdersReadResponseOrdersInnerFromJSONTyped,
  OrdersReadResponseOrdersInnerToJSON
} from './OrdersReadResponseOrdersInner';

/**
 *
 * @export
 * @interface OrdersReadResponse
 */
export interface OrdersReadResponse {
  /**
   *
   * @type {boolean}
   * @memberof OrdersReadResponse
   */
  success: boolean;
  /**
   *
   * @type {string}
   * @memberof OrdersReadResponse
   */
  exception_code?: string;
  /**
   *
   * @type {string}
   * @memberof OrdersReadResponse
   */
  exception_message?: string;
  /**
   *
   * @type {CustomersReadResponseMeta}
   * @memberof OrdersReadResponse
   */
  meta: CustomersReadResponseMeta;
  /**
   *
   * @type {Array<OrdersReadResponseOrdersInner>}
   * @memberof OrdersReadResponse
   */
  orders: Array<OrdersReadResponseOrdersInner>;
  /**
   *
   * @type {Array<DataTableFiltersCreateResponseFilter>}
   * @memberof OrdersReadResponse
   */
  filters: Array<DataTableFiltersCreateResponseFilter>;
}

/**
 * Check if a given object implements the OrdersReadResponse interface.
 */
export function instanceOfOrdersReadResponse(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'success' in value;
  isInstance = isInstance && 'meta' in value;
  isInstance = isInstance && 'orders' in value;
  isInstance = isInstance && 'filters' in value;

  return isInstance;
}

export function OrdersReadResponseFromJSON(json: any): OrdersReadResponse {
  return OrdersReadResponseFromJSONTyped(json, false);
}

export function OrdersReadResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): OrdersReadResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    success: json['success'],
    exception_code: !exists(json, 'exception_code') ? undefined : json['exception_code'],
    exception_message: !exists(json, 'exception_message') ? undefined : json['exception_message'],
    meta: CustomersReadResponseMetaFromJSON(json['meta']),
    orders: (json['orders'] as Array<any>).map(OrdersReadResponseOrdersInnerFromJSON),
    filters: (json['filters'] as Array<any>).map(DataTableFiltersCreateResponseFilterFromJSON)
  };
}

export function OrdersReadResponseToJSON(value?: OrdersReadResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    success: value.success,
    exception_code: value.exception_code,
    exception_message: value.exception_message,
    meta: CustomersReadResponseMetaToJSON(value.meta),
    orders: (value.orders as Array<any>).map(OrdersReadResponseOrdersInnerToJSON),
    filters: (value.filters as Array<any>).map(DataTableFiltersCreateResponseFilterToJSON)
  };
}
