/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { QuestionChoiceTranslationSchema } from './QuestionChoiceTranslationSchema';
import {
  QuestionChoiceTranslationSchemaFromJSON,
  QuestionChoiceTranslationSchemaFromJSONTyped,
  QuestionChoiceTranslationSchemaToJSON
} from './QuestionChoiceTranslationSchema';

/**
 *
 * @export
 * @interface QuestionChoiceByIdReadResponseQuestionChoice
 */
export interface QuestionChoiceByIdReadResponseQuestionChoice {
  /**
   *
   * @type {string}
   * @memberof QuestionChoiceByIdReadResponseQuestionChoice
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof QuestionChoiceByIdReadResponseQuestionChoice
   */
  partner_id: string;
  /**
   *
   * @type {string}
   * @memberof QuestionChoiceByIdReadResponseQuestionChoice
   */
  partner_question_id: string;
  /**
   *
   * @type {string}
   * @memberof QuestionChoiceByIdReadResponseQuestionChoice
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof QuestionChoiceByIdReadResponseQuestionChoice
   */
  status: QuestionChoiceByIdReadResponseQuestionChoiceStatusEnum;
  /**
   *
   * @type {Array<QuestionChoiceTranslationSchema>}
   * @memberof QuestionChoiceByIdReadResponseQuestionChoice
   */
  translations?: Array<QuestionChoiceTranslationSchema>;
}

/**
 * @export
 */
export const QuestionChoiceByIdReadResponseQuestionChoiceStatusEnum = {
  Active: 'active',
  Deleted: 'deleted'
} as const;
export type QuestionChoiceByIdReadResponseQuestionChoiceStatusEnum =
  (typeof QuestionChoiceByIdReadResponseQuestionChoiceStatusEnum)[keyof typeof QuestionChoiceByIdReadResponseQuestionChoiceStatusEnum];

/**
 * Check if a given object implements the QuestionChoiceByIdReadResponseQuestionChoice interface.
 */
export function instanceOfQuestionChoiceByIdReadResponseQuestionChoice(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'id' in value;
  isInstance = isInstance && 'partner_id' in value;
  isInstance = isInstance && 'partner_question_id' in value;
  isInstance = isInstance && 'name' in value;
  isInstance = isInstance && 'status' in value;

  return isInstance;
}

export function QuestionChoiceByIdReadResponseQuestionChoiceFromJSON(
  json: any
): QuestionChoiceByIdReadResponseQuestionChoice {
  return QuestionChoiceByIdReadResponseQuestionChoiceFromJSONTyped(json, false);
}

export function QuestionChoiceByIdReadResponseQuestionChoiceFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): QuestionChoiceByIdReadResponseQuestionChoice {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    partner_id: json['partner_id'],
    partner_question_id: json['partner_question_id'],
    name: json['name'],
    status: json['status'],
    translations: !exists(json, 'translations')
      ? undefined
      : (json['translations'] as Array<any>).map(QuestionChoiceTranslationSchemaFromJSON)
  };
}

export function QuestionChoiceByIdReadResponseQuestionChoiceToJSON(
  value?: QuestionChoiceByIdReadResponseQuestionChoice | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    partner_id: value.partner_id,
    partner_question_id: value.partner_question_id,
    name: value.name,
    status: value.status,
    translations:
      value.translations === undefined
        ? undefined
        : (value.translations as Array<any>).map(QuestionChoiceTranslationSchemaToJSON)
  };
}
