/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CustomersReadResponseMeta } from './CustomersReadResponseMeta';
import {
  CustomersReadResponseMetaFromJSON,
  CustomersReadResponseMetaFromJSONTyped,
  CustomersReadResponseMetaToJSON
} from './CustomersReadResponseMeta';
import type { PromoCodeReadResponsePromoCodesInner } from './PromoCodeReadResponsePromoCodesInner';
import {
  PromoCodeReadResponsePromoCodesInnerFromJSON,
  PromoCodeReadResponsePromoCodesInnerFromJSONTyped,
  PromoCodeReadResponsePromoCodesInnerToJSON
} from './PromoCodeReadResponsePromoCodesInner';

/**
 *
 * @export
 * @interface PromoCodeReadResponse
 */
export interface PromoCodeReadResponse {
  /**
   *
   * @type {boolean}
   * @memberof PromoCodeReadResponse
   */
  success: boolean;
  /**
   *
   * @type {string}
   * @memberof PromoCodeReadResponse
   */
  exception_code?: string;
  /**
   *
   * @type {string}
   * @memberof PromoCodeReadResponse
   */
  exception_message?: string;
  /**
   *
   * @type {Array<PromoCodeReadResponsePromoCodesInner>}
   * @memberof PromoCodeReadResponse
   */
  promo_codes: Array<PromoCodeReadResponsePromoCodesInner>;
  /**
   *
   * @type {CustomersReadResponseMeta}
   * @memberof PromoCodeReadResponse
   */
  meta: CustomersReadResponseMeta;
}

/**
 * Check if a given object implements the PromoCodeReadResponse interface.
 */
export function instanceOfPromoCodeReadResponse(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'success' in value;
  isInstance = isInstance && 'promo_codes' in value;
  isInstance = isInstance && 'meta' in value;

  return isInstance;
}

export function PromoCodeReadResponseFromJSON(json: any): PromoCodeReadResponse {
  return PromoCodeReadResponseFromJSONTyped(json, false);
}

export function PromoCodeReadResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PromoCodeReadResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    success: json['success'],
    exception_code: !exists(json, 'exception_code') ? undefined : json['exception_code'],
    exception_message: !exists(json, 'exception_message') ? undefined : json['exception_message'],
    promo_codes: (json['promo_codes'] as Array<any>).map(
      PromoCodeReadResponsePromoCodesInnerFromJSON
    ),
    meta: CustomersReadResponseMetaFromJSON(json['meta'])
  };
}

export function PromoCodeReadResponseToJSON(value?: PromoCodeReadResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    success: value.success,
    exception_code: value.exception_code,
    exception_message: value.exception_message,
    promo_codes: (value.promo_codes as Array<any>).map(PromoCodeReadResponsePromoCodesInnerToJSON),
    meta: CustomersReadResponseMetaToJSON(value.meta)
  };
}
